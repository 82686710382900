<template>
	<div>
		<pui-datatable :modelName="modelName" :modelColumnDefs="modelColumnDefs"></pui-datatable>
	</div>
</template>

<script>
export default {
	name: 'anchoragegrid',
	components: {},
	data() {
		return {
			modelName: 'vstopanchorage',
			modelColumnDefs: {
				status: {
					render: (data, type, row) => {
						if (row.statusoutlinecolor && row.statuscolor) {
							return `<label style="color: white; background-color: ${row.statuscolor}; border: 2px solid ${row.statusoutlinecolor}; font-weight: bold; padding: 3px; border-radius: 10px">${data}</label>`;
						} else {
							return data;
						}
					}
				}
			}
		};
	}
};
</script>
